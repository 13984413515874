import { Controller } from 'stimulus'

export default class extends Controller {
  connect() {
    this.scrollToBottom()
  }

  scrollToBottom() {
    const element = document.querySelector('#db_logs')

    if (this.nearBottom()) {
      element.scrollTop = element.scrollHeight
    }
  }

  nearBottom() {
    const element = document.querySelector('#db_logs')

    return element.scrollTop >= element.scrollHeight - element.clientHeight - 100
  }
}
